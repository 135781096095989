import ApiHandler from '@/api/ApiHandler';

// Enpoints alias
const ENDPOINTS = 'order';

class ApiOrderHandler extends ApiHandler {

    submitOrder(...args) {
        this.apiHandler(this.api.post, ENDPOINTS, ...args);
    }
}

export default ApiOrderHandler;