import React, { Component, Fragment } from 'react';

// Components
import { Col, Row, Container } from 'react-bootstrap';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import PickupModal from './components/PickupModal';

import {CITIES} from '@/globals';

import styles from './Pickups.css';

class Pickups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShowed: false,
            modalCertificateShowed: false,
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.refs.map.leafletElement.invalidateSize(true);
        }, 300);
    }

    // Handlers

    showModal(event){

        const role = event.currentTarget.dataset.role

        const key = role === "pickup" ?
            "modalShowed" : "modalCertificateShowed"

        this.setState({[key]: true});
    }

    closeModal() {
        this.setState({
            modalShowed: false,
            modalCertificateShowed: false
        });
    }

    // Render Methods

    renderCities() {

        let delta = Object.keys(CITIES).length % 2 === 0 ? 0 : 1;
        let middleCities = parseInt(Object.keys(CITIES).length/2) + delta;

        return (
            <Row>
                <Col>
                    <ul>
                        {Object.keys(CITIES).slice(0, middleCities).map((city) => {
                            return <li key={city}>{city}</li>
                        })}
                    </ul>
                </Col>
                <Col>
                    <ul>
                        {Object.keys(CITIES).slice(middleCities).map((city) => {
                            return <li key={city}>{city}</li>
                        })}
                    </ul>
                </Col>
            </Row>
        )
    }

    renderMarkers() {
        return Object.keys(CITIES).map((cityName) => {
            let point = CITIES[cityName];
            return (
                <Marker key={cityName} position={point}>
                    <Popup>
                        {cityName}
                    </Popup>
                </Marker>
            );
        });
    }

    renderInfo() {
        return (
            <Row>
                <Col>
                    <div className="section_title text-right">
                        <h3 className="title">
                            Recogidas
                            <span className="heading_line"></span>
                        </h3>
                        <div>
                            <p className="text-left">En Sacos Los Gemelos, nos encargamos de recoger sus sacos de escombro para que usted
                                no tenga que preocuparse de nada. <br/>
                            </p>
                            <div className="alert alert-primary text-left">
                                <h5>Recordar que...</h5>
                                <p className="text-left">
                                    Los sacos deben estar depositados en
                                    la vía pública, ya que dentro de patios, garajes, urbanizaciones cerradas y cualquier
                                    lugar donde la presencia del cliente sea necesaria, No tenemos servicio de retirada.
                                    Igualmente en ciudades que no esten citadas a continuación, No tenemos servicio de retirada.
                                </p>
                            </div>
                            <div className="alert alert-primary text-left">
                                <h5>Importante!</h5>
                                <p className="text-left">
                                    El municipio de Torrejón de Ardoz permite únicamente el servicio de contenedores,
                                    ya que la instalación de sacos de escombro de 1m3 está terminantemente prohibida.
                                </p>
                            </div>
                            <p className="text-left">
                                Sacos Los Gemelos tiene servicio de recogida en las siguientes ciudades:
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    renderMap() {
        return (
            <Row>
                <Col lg={8} md={12}>
                    <Map ref="map" center={CITIES.Madrid} zoom={10}>
                        <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.renderMarkers()}
                    </Map>
                </Col>
                <Col lg={4} md={12}>
                    <Container>
                        {this.renderCities()}
                    </Container>
                </Col>
            </Row>
        )
    }

    renderPickupButton() {
        return (
            <Row>
                <Col xs={12} lg={8} xl={9}>
                    <div
                        className="icon_box_four wow animated"
                        data-role="pickup"
                        onClick={this.showModal}
                    >
                        <div className={styles.ButtonContainer}>
                            <i className="ion-ios-arrow-dropdown-circle-outline "></i>
                            <div className="content">
                                <h5 className="title color-blue">
                                    Dar Aviso de Recogida
                                </h5>
                                <p className="text ">
                                    Si quieres programar la recogida de sus sacos de escombro pinche aquí
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={4} xl={3}>
                    <div
                        className="icon_box_four wow animated"
                        data-role="certificate"
                        onClick={this.showModal}
                    >
                        <div className={styles.ButtonContainer}>
                            <i className="icon ion-ios-document"></i>
                            <div className="content">
                                <h5 className="title color-blue">
                                    Certificado
                                </h5>
                                <p>Trámitar certificado de vertedero</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <Fragment>
                <section id="pickups" className="pt120 pb50 bg-light">
                    <Container>
                        {this.renderInfo()}
                        {this.renderMap()}
                        {this.renderPickupButton()}
                    </Container>
                </section>
                <PickupModal show={this.state.modalShowed}
                    handleClose={this.closeModal}
                    certifificateOnly={false}
                ></PickupModal>
                <PickupModal show={this.state.modalCertificateShowed}
                    handleClose={this.closeModal}
                    certifificateOnly={true}
                ></PickupModal>
            </Fragment>
        )
    }
}

export default Pickups;