import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

// Assets

import styles from './Catalog.css';
import m_box from './saco-grande.png';
import small_box from './saco-pequeno.png';

class Catalog extends Component {

    constructor(props) {
        super(props);
        this.catalogItems = [
            {
                img: m_box,
                alt: "1m³",
                title: `Saco 1m³`,
                description: `Saco de escombro de alta resistencia de 1m³. Se instala
                              facilmente en la vía pública y el servicio de recogida está incluido`
            },
            {
                img: small_box,
                alt: "saco de pequeño de rafia",
                title: `Saco pequeño de rafia`,
                description: `Saco de escombro pequeño. Le ofrece una gran resistencia en
                              el mínimo espacio.`
            },
        ];
    }

    // render methods

    renderTitle() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col md={7}>
                        <div className="section_title text-center mb50 fadeIn wow  " >
                            <h3 className="title">
                                Catálogo
                                <span className="heading_line"></span>
                            </h3>
                            <p>
                                Nuestros sacos son de la más alta calidad, ofreciendole la máxima resistencia de la forma
                                más compacta, en un espacio reducido. Además, para que usted no tenga que preocuparse de
                                nada, el servicio de recogida está incluido. Esto es sinónimo de comodidad y tranquilidad.
                                <br/>Nuestros productos son los siguientes:
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderCatalog() {
        return (
            <Container>
                <div className={`justify-content-center filtr-container ${styles.catalogContainer}`}>
                    {this.renderItems()}
                </div>
            </Container>
        )
    }

    renderItems() {

        const md = parseInt(12 / this.catalogItems.length)

        return this.catalogItems.map((item, i) => {
            return <CatalogItem
                        key={i}
                        md={md}
                        img={item.img}
                        alt={item.alt}
                        title={item.title}
                        description={item.description}
                    />
        })
    }

    render() {
        return (
            <section className="pt120 pb120 bg-white">
                <div>
                    {this.renderTitle()}
                    {this.renderCatalog()}
                </div>
            </section>
        )
    }
}

function CatalogItem(props){
    return (
        <Col md={12} lg={props.md} className="filtr-item" data-category="1">
            <a href={props.img} title={props.alt}>
                <div className="project_box_one">
                    <img src={props.img} alt={props.alt} />
                    <div className="product_info">
                        <div className="product_info_text">
                            <div className="product_info_text_inner">
                                <h5>{props.title}</h5>
                                <p>
                                    {props.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Col>
    )
}

export default  Catalog;