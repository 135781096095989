import ApiHandler from '@/api/ApiHandler';

// Enpoints alias
const ENDPOINTS = 'joboffer';

class ApiWorkWithUsHandler extends ApiHandler {

    sendResume(data, handler) {

        const args = [null, data, handler]


        this.apiHandler(this.api.put, ENDPOINTS, ...args);
    }
}

export default ApiWorkWithUsHandler;