import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './News.css';

class News extends Component {

    constructor(props){
        super(props);

        this.news = [

        ]

        toast.configure({
            autoClose: 10000,
            draggable: false,
            enableMultiContainer: true,
            className: styles.newsToast,
            toastClassName: 'pink-150'
        })

        this.notifyDelay = 3500;

        this.notify = this.notify.bind(this);

    }

    notify() {
        setTimeout(() => {
            this.news.forEach((newsItem, index) => {
                toast.info(newsItem, {
                    ...this.defaultToastConfig,
                })
            });
          }, this.notifyDelay)
    }

    render(){
      return (
        <div>
          {this.notify()}
        </div>
      );
    }
}

export default News;