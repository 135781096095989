import React, { Component } from 'react';
import {Form, Button} from 'react-bootstrap';

// Components
import PrivacyCheckbox from './PrivacyCheckbox';
import FormErrorMsg from '@/components/FormErrorMsg';


// Assets
import styles from './CustomForm.css';

class CustomForm extends Component {

    /**
     * props description
     *
     * [button]         jsx with button to render in form
     * policy           policy state of father component
     * submitHandler    handler when form submited
     * policyHandler    handler to handle the policy check changes
     */
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.persist();
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false || !this.props.policy) {
          this.setState({ validated: true });
          return;
        }

        this.resetForm();

        this.props.submitHandler(event);
      }

    // Methods

    resetForm() {
        this.setState({
            validated: false,
        });
    }

    // Reders

    renderButton() {
        if (this.props.button) {
            return this.props.button;
        } else {
            return (
                <Button variant="primary" type="submit">
                    Enviar
                </Button>
            )
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}
                noValidate
                validated={this.state.validated}
                className={styles.customForm}
            >
                {this.props.children}
                <PrivacyCheckbox handler={this.props.policyHandler} checked={this.props.policy}/>
                <FormErrorMsg validated={this.state.validated} auxMsg={this.props.errorMsg} />
                {this.renderButton()}
            </Form>
        )
    }
}

export default CustomForm;