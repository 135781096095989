import React from 'react'

// Assets

import styles from './FormErrorMsg.css'

export default function FormErrorMsg(props) {

    const errorMsg = (
        <div className={`alert alert-primary ${styles.formError}`} >
            <div className={`icon_box_five p0   wow   animated ${styles.FormErrorIconBox}`}>
                <i className="ion-ios-alert-outline"></i>
                <div className="content">
                    <h5 className={styles.formErrorTitle}>
                        Los campos no están rellenos correctamente
                    </h5>
                    <p className={styles.FormErrorText}>
                        Algún campo no esta relleno correctamente. Por favor revise el formulario
                        y <span className={styles.FormErrorResaltedText}>
                            rellene los campos sombreados
                        </span>
                    </p>
                    <p>
                        {props.auxMsg}
                    </p>
                </div>
            </div>
        </div>
    )

    return props.validated ? errorMsg : null
}
