import config from '../config';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Callback to handle reply data. Is necessary to bind(this) in the context where
 * it is called.
 *
 * @callback replyCallback
 * @param {int}    http code status returned
 * @param {Object} data Data replied for the api
 */

class Api {

  constructor(){

    this.endpoints = {};
    this.ready = false;

    // set Endpoints
    this.get('/')
      .then((data) => {
        if (data.status >= 400) {
          const error = {
            status: data.status,
            body: `Api unavailable: ${data.body}`
          };
          throw error;
        }
        this.endpoints = data.body;
        this.ready = true;
      })
      .catch((err) => {
        console.error(err);
      });

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  sendRequest(url, params, headers = {}) {

    // Update headers with default headers and overwrite with specific headers

    const defaultConfig = cloneDeep(config.apiBasicConfig);

    let defaultParams = {
      ...defaultConfig,
      ...params
    };

    // If is upload file (multipart/form-data) remove content-type to automatic
    // boundary calculation

    if (!(params.body instanceof FormData)) {
        defaultParams.headers = {
          ...defaultParams.headers,
          ...headers
        };
    } else {
      delete defaultParams.headers["Content-Type"];
    }

    const absUrl = process.env.NODE_ENV === "development" ?
      `${window.location.protocol}//localhost:${config.apiDevPort}/${url.replace(/^\//g, '')}` :
      `${config.apiUrl[process.env.REACT_APP_TARGET_ENV]}/${url.replace(/^\//g, '')}`

    return fetch(absUrl, defaultParams)
      .then((response) => {
        if (!response.ok) {
            const error = {
              status: response.status,
              body: `${response.statusText}`
            };
            throw error;
        }
        return response.json().then((data) => {
          return {
            status: response.status,
            body: data
          };
        });
      })
      .catch((error) => {

        if (!error.hasOwnProperty('status')) throw error;

        const errorMsg = `Error ${defaultParams.method} ${url} ${error.status}: ${error.body}`;
        console.error(errorMsg);
        if (error.status === 429) alert("Has realizado demasiadas peticiones");
        return {
          ...error,
          body: errorMsg
        };
      });

  }


  /**
  * Request get to specific endpoint
  *
  * This method send a get request to the endpoint specified in parameters. If
  * handler is passed as argument, this handler will have access to reply data.
  *
  * @param {string}         endpoint    Relative api endpoint where you want to do requeste
  * @param {string}         [id]        Id of specific api-rest resource
  * @param {Object}         [headers]   Specific headers to make request.
  * @param {replyCallback}  [handler]   Callback to handle the replied data of the api server.
  *
  * @return {Promise} Return promise with replied data.
  */
  get(endpoint, ...args) {

    let id;
    let headers;
    let handler;

    if (args.length > 0) {
      handler = (typeof args[args.length-1] === 'function') ? args.pop() : null;
      id = (args.length > 0) ? args.shift() : null;
      headers = (args.length > 0) ? args.shift() : null;
    }

    const params = {
        method: 'GET',
    };

    endpoint = id ? `${endpoint}/${id}` : endpoint;

    return !handler ?
      this.sendRequest(endpoint, params, headers) :
      this.sendRequest(endpoint, params, headers).then((data) => {
        handler(data.status, data.body);
        return data;
      });
  }

  /**
  * Request post to specific endpoint
  *
  * This method send a post request to the endpoint specified in parameters. If
  * handler is passed as argument, this handler will have access to reply data.
  *
  * @param {string}         endpoint    Relative api endpoint where you want to do requeste
  * @param {Object}         data        Data in json where will be send to the api server
  * @param {Object}         [headers]   Specific headers to make request.
  * @param {replyCallback}  [handler]   Callback to handle the replied data of the api server.
  *
  * @return {Promise} Return promise with replied data.
  */
  post(endpoint, ...args) {

    let data;
    let headers;
    let handler;

    if (args.length > 0) {
      handler = (typeof args[args.length-1] === 'function') ? args.pop() : null;
      data = (args.length > 0) ? args.shift() : null;
      headers = (args.length > 0) ? args.shift() : null;
    }

    const body = data instanceof FormData ? data : JSON.stringify(data);

    const params = {
        body: body,
        method: 'POST',
    };

    return !handler ?
      this.sendRequest(endpoint, params, headers) :
      this.sendRequest(endpoint, params, headers).then((data) => {
        handler(data.status, data.body);
        return data;
      });
  }

  /**
  * Request put to specific endpoint
  *
  * This method send a put request to the endpoint specified in parameters. If
  * handler is passed as argument, this handler will have access to reply data.
  *
  * @param {string}         endpoint    Relative api endpoint where you want to do requeste
  * @param {string}         id          Id of specific api-rest resource
  * @param {Object}         data        Data in json where will be send to the api server
  * @param {Object}         [headers]   Specific headers to make request.
  * @param {replyCallback}  [handler]   Callback to handle the replied data of the api server.
  *
  * @return {Promise} Return promise with replied data.
  */
  put(endpoint, ...args) {

    let data;
    let id;
    let headers;
    let handler;

    if (args.length > 0) {
      handler = (typeof args[args.length-1] === 'function') ? args.pop() : null;
      id = (args.length > 0) ? args.shift() : null;
      data = (args.length > 0) ? args.shift() : null;
      headers = (args.length > 0) ? args.shift() : null;
    }

    const body = data instanceof FormData ? data : JSON.stringify(data);

    const params = {
        body: body,
        method: 'PUT',
    };

    endpoint = id ? `${endpoint}/${id}` : endpoint;

    return !handler ?
      this.sendRequest(endpoint, params, headers) :
      this.sendRequest(endpoint, params, headers).then((data) => {
        handler(data.status, data.body);
        return data;
      });
  }

  /**
  * Request delete to specific endpoint
  *
  * This method send a delete request to the endpoint specified in parameters. If
  * handler is passed as argument, this handler will have access to reply data.
  *
  * @param {string}         endpoint    Relative api endpoint where you want to do requeste
  * @param {string}         id          Id of specific api-rest resource
  * @param {Object}         [headers]   Specific headers to make request.
  * @param {replyCallback}  [handler]   Callback to handle the replied data of the api server.
  *
  * @return {Promise} Return promise with replied data.
  */
  delete(endpoint, ...args) {

    let id;
    let headers;
    let handler;

    if (args.length > 0) {
      handler = (typeof args[args.length-1] === 'function') ? args.pop() : null;
      id = (args.length > 0) ? args.shift() : null;
      headers = (args.length > 0) ? args.shift() : null;
    }

    const params = {
        method: 'DELETE',
    };

    endpoint = `${endpoint}/${id}`;

    return !handler ?
      this.sendRequest(endpoint, params, headers) :
      this.sendRequest(endpoint, params, headers).then((data) => {
        handler(data.status, data.body);
        return data;
      });
  }

}

export default Api;