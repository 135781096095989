module.exports = {
    apiRoot: "/api/",
    apiDevPort: "8000",
    apiBasicConfig: {
        headers: {
            "Content-Type": "application/json"
        },
        cache: "default"
    },
    apiUrl: {
        stage: "https://ix8zbctfhk.execute-api.eu-central-1.amazonaws.com",
        prod: "https://rnrc4g74xb.execute-api.eu-central-1.amazonaws.com"
    }
}