import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// Import components
import CookieMessage from '@/components/Alert/CookieAlert';
import Header from '@/sections/Header/Header';
import News from '@/sections/News';
import Advices from '@/sections/Advices';
import Footer from '@/sections/Footer/Footer';
import Pickups from '@/sections/Pickups/Pickups';
import Orders from '@/sections/Orders/Orders';
import Contact from '@/sections/Contact/Contact';
import Catalog from '@/sections/Catalog/Catalog';
import Maintenance from './sections/Maintenance';


if (process.env.REACT_APP_MAINTENANCE === "true") {
    ReactDOM.render(<Maintenance />, document.getElementById('maintenance'));
} else {
    ReactDOM.render(<CookieMessage />, document.getElementById('cookie'));
    ReactDOM.render(<Header />, document.getElementById('header'));
    ReactDOM.render(<Advices />, document.getElementById('advices'));
    ReactDOM.render(<News />, document.getElementById('news'));
    ReactDOM.render(<Footer />, document.getElementById('footer'));
    ReactDOM.render(<Pickups />, document.getElementById('pickups'));
    ReactDOM.render(<Orders />, document.getElementById('orders'));
    ReactDOM.render(<Contact />, document.getElementById('contact'));
    ReactDOM.render(<Catalog />, document.getElementById('catalog'));
}


serviceWorker.unregister();
