import React, { Component } from 'react';
import {Col, Row, Container} from 'react-bootstrap';

// Components

import OrderForm from './components/OrderForm';

// Assets
import styles from './Orders.css';

class Orders extends Component {

    constructor(props) {
        super(props);

        this.partners_premium = {
            bricomart: {
                web: "https://www.bricomart.es/",
                logo: "bricomart.png",
                name: "Bricomart",
            },
            borreguero: {
                web: "https://goo.gl/maps/Ek2Kp2pxqANsUd4F6",
                logo: "borreguero.png",
                name: "Pinturas Borreguero",
                size: "2em",
            },
            mirandesa: {
                web: "https://goo.gl/maps/NJVpkn3ZZ6wB3MgJ7",
                logo: "mirandesa.png",
                name: "Mirandesa",
            },
            muller: {
                web: "https://goo.gl/maps/uWhV16tQgkmXLHKK6",
                logo: "muller.png",
                name: "Pinturas Muller",
            },
            hermanos_herrero: {
                web: "https://hermanosherrero.es/",
                logo: "herrero.png",
                name: "Hermanos herrero",
            },
            sorigas: {
                web: "http://www.sorigas.es/",
                logo: "sorigas.png",
                name: "Sorigas",
            },
            carmar: {
                web: "http://www.factoriacolor.com/",
                name: "Carmar",
            },
        };

        this.partners = {
            pinturas_sanguino: {
                web: "https://pinturassanguino.com",
                name: "Pinturas Sanguino",
            },
            colores_ambientes: {
                web: "http://www.coloresyambientes.es",
                name: "Colores y ambientes",
            },
            pinturas_hurtado: {
                web: "https://www.tiendaspinturas.es",
                name: "Pinturas Hurtado",
            },
            hogar_hobby: {
                web: "https://hogaryhobby.es",
                name: "Hogar y Hobby",
            },
            palomares: {
                web: "https://www.escayola-madrid.com/",
                logo: "palomares.png",
                name: "Escayolas Palomares",
            },
        };
    }

    renderDistributorsList() {
        const partners = {
            ...this.partners_premium,
            ...this.partners
        }
        const distList = Object.keys(partners).map((partner) => {
            const size = Math.round((Math.random() * 3 + 1) * 100) / 100;
            const partnerStyle = {
                fontSize: `${size}em`
            };
            const parnertHTML = (
                <span key={partner} className={styles.distributorList}>
                    <a style={partnerStyle} href={partners[partner].web} target="_blank" rel="noopener noreferrer">
                        {partners[partner].name}
                    </a>
                </span>
            );
            return parnertHTML;
        });



        return distList;
    }

    renderParticular() {
        return (
            <Col md={6}>
                <Row>
                    <h5 className={styles.orderColSubtitle}>
                        Si usted es un usuario particular, debe saber que:
                    </h5>
                </Row>
                <Row>
                    <Col>
                        <p>
                        Suministramos sacos de escombro únicamente a empresas. Si usted
                        es particular y necesita sacos para su obra o reforma, puede
                        comprarlos en nuestros
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Puntos de Venta:</h4>
                        <div className={styles.distributorContainer}>
                            {this.renderDistributorsList()}
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }

    renderDistributorForm() {
        return (
            <Col md={6}>
                <Row>
                    <h5 className={styles.orderColSubtitle}>
                        Si eres distribuidor nuestro, puedes realizar un pedido en el siguiente formulario:
                    </h5>
                </Row>
                <Row>
                    <OrderForm/>
                </Row>
            </Col>
        )
    }

    render() {
        return (
            <section id="orders_section"  className="bg-light">
                <Container className="pt120 pb120">
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section_title text-left mb50 fadeIn wow  " >
                                <h3 className="title">
                                    Pedidos
                                    <span className="heading_line"></span>
                                </h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {this.renderParticular()}
                        {this.renderDistributorForm()}
                    </Row>
                </Container>
            </section>
        )
    }
}

export default  Orders;