import ApiHandler from '@/api/ApiHandler';

// Enpoints alias
const ENDPOINTS = 'contactus';

class ApiContactHandler extends ApiHandler {

    sendAnswer(...args) {
        this.apiHandler(this.api.post, ENDPOINTS, ...args);
    }
}

export default ApiContactHandler;