import React, { Component } from 'react';

// Components
import { Navbar, Container, Row, Col } from 'react-bootstrap';

// Import assets
import styles from './Header.css';
import logo from './logo.png';

class Header extends Component {

    renderNavbar() {

        return (
            <Navbar className={styles.header} expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <Row>
                            <Col xs={12} sm={8} md={6} lg={7} xl={6}>
                                <div className={styles.logoContainer}>
                                    <img className={styles.logo} src={logo} alt="Los Gemelos" />
                                    <h1 className={styles.title}>Sacos Los Gemelos</h1>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={6} lg={5} xl={6} className={styles.subtitleContainer}>
                            <h2 className={styles.subtitle}>Tus Sacos de escombro en Madrid</h2>
                            </Col>
                        </Row>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        )
    }

    render() {
        return (
            <div>
                {this.renderNavbar()}
            </div>
        );
    }

}

export default Header;
