import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from '@/components/Modal/ConfirmModal';
import Spinner from '@/components/Spinner';

import ApiWorkWithUs from './ApiWorkWithUs';

import { FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import "filepond/dist/filepond.min.css";
import styles from './WorkWithUsModal.css';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const WAITING_FILES = 1;
const WAITING_RESPONSE = 2;
const RESPONSE_RECEIVED = 3;

class WorkWithUsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: null,
            validationLabel: "",
            modalState: WAITING_FILES,
            apiError: false
        }

        this.api = new ApiWorkWithUs();

        // File size validation properties (In MB)
        this.maxFiles = 5
        this.maxFileSize = 5;
        this.maxTotalFileSize = this.maxFiles * this.maxFileSize;

        // File types validation properties
        this.fileTypesAllowed = {
            pdf: 'application/pdf',
            txt: 'text/plain',
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            odt: 'application/vnd.oasis.opendocument.text',
            rtf: 'application/rtf',
            md: 'text/x-markdown',
            png: 'image/png',
            jpeg: 'image/jpeg'
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
    }

    // handlers

    handleConfirm() {
        if (this.checkAllFilesValid()) {

            const data = new FormData();

            this.state.files.forEach((file, i) => data.append(`file${i}`, file, file.name))

            this.api.sendResume(data, this.handleApiResponse);

            this.setState({
                modalState: WAITING_RESPONSE,
            });

        }
    }

    handleClose() {
        // reset state on close
        this.setState({
            files: null,
            validationLabel: "",
            modalState: WAITING_FILES,
            apiError: false
        });
        this.props.handleClose()
    }

    handleDrop(fileItems) {
        this.setState({
            files: fileItems.map(fileItem => fileItem.file)
        })
    }

    handleApiResponse(status, data) {
        const apiError = status >= 400
        this.setState({
            apiError: apiError,
            modalState: RESPONSE_RECEIVED
        });

        setTimeout(this.handleClose, 3000);
    }

    // Methods

    allowedFileTypesLabel() {

        const [firstType, ...nextTypes] = Object.keys(this.fileTypesAllowed);

        const alloweFilesTypesStrMap =  '{' + nextTypes.reduce(
            (acc, key) => `${acc}, '${this.fileTypesAllowed[key]}': '.${key}'`,
            `'${this.fileTypesAllowed[firstType]}': '.${firstType}'`
        ) + '}';

        return alloweFilesTypesStrMap

    }

    checkAllFilesValid() {

        let anyFileAdded = document.querySelectorAll(".filepond--item").length > 0;

        if (!anyFileAdded) {
            this.setState({
                validationLabel: "Debes adjuntar algún archivo"
            })

            return anyFileAdded;
        }

        let allFilesItems = document.querySelectorAll(".filepond--item[data-filepond-item-state=load-invalid]");

        const validFiles = allFilesItems.length === 0;

        if (!validFiles) {
            this.setState({
                validationLabel: "Algún archivo adjunto no es válido"
            })
        }

        return validFiles;
    }

    // renders

    renderDropDownBox() {
        return (
            <FilePond
                ref={ref => (this.pond = ref)}
                files={this.state.files}
                allowMultiple={true}
                maxFiles={5}
                labelIdle="Arrastra aquí tus ficheros o pincha para seleccionarlos desde tu explorador de archivos"
                onupdatefiles={this.handleDrop}
                // File size validation
                allowFileSizeValidation={true}
                maxFileSize={`${this.maxFileSize}MB`}
                maxTotalFileSize={`${this.maxTotalFileSize}MB`}
                labelMaxFileSize={'Ha excedido el tamaño máximo para el archivo. Tamaño máximo permitido {filesize}'}
                labelMaxFileSizeExceeded={'El archivo excede el tamaño máximo'}
                labelMaxTotalFileSize={'Ha excedido el tamaño máximo total. Tamaño máximo total permitido {filesize}'}
                labelMaxTotalFileSizeExceeded={'Se ha excedido el tamaño máximo total'}
                // File type validation
                allowFileTypeValidation={true}
                acceptedFileTypes={Object.values(this.fileTypesAllowed)}
                labelFileTypeNotAllowed="El tipo de archivo no está permitido"
                fileValidateTypeLabelExpectedTypes="Tipos de archivo permitidos {allButLastType} or {lastType}"
                fileValidateTypeLabelExpectedTypesMap={this.allowedFileTypesLabel()}
            />
        )
    }

    renderWaitingFiles() {
        return (
            <Container>
                <Row>
                    <Col>
                    Arrastra aqui tu curriculum, carta de presentación, carta de recomendación, etc:
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {this.renderDropDownBox()}
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.validationLabel}>
                    {this.state.validationLabel}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>
                            Este curriculum va a ser enviado por correo y no va a ser almacenado en ninguna
                            base de datos. Los datos personales contenidos en él, en ningún caso van a ser
                            proporcionados a terceros. Si quiere más información del tratamiento de sus datos
                            consulte nuestra sección de política de privacidad en el pie de la página.
                            Si desea que su curriculum sea borrado de nuestro correo solicitelo
                            en <a href="mailto:info@sacoslosgemelos.com">info@sacoslosgemelos.com</a>

                        </small>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderWaitingResponse() {
        return (
            <Container>
                <Row>
                    <Col className={styles.spinnerContainer}>
                        <Spinner></Spinner>
                    </Col>
                    <Col className={styles.spinnerLabel}>
                        Tu candidatura se esta enviado. Espere un momento
                    </Col>
                </Row>
            </Container>)
    }

    renderResponseReceived() {
        return !this.state.apiError ? "Su candidatura se ha recibido correctamente" :
               "Ha habido algún problema y no hemos recibido su candidatura"
    }

    renderModalBody() {
        switch (this.state.modalState) {
            case WAITING_FILES:
                return (
                    <React.Fragment>
                        {this.renderWaitingFiles()}
                    </React.Fragment>
                );
            case WAITING_RESPONSE:
                return (
                    <React.Fragment>
                        {this.renderWaitingResponse()}
                    </React.Fragment>
                );
            case RESPONSE_RECEIVED:
                return (
                    <div>
                        {this.renderResponseReceived()}
                    </div>
                );
            default:
                return (
                    <React.Fragment>
                        {this.renderWaitingFiles()}
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <Modal title="Trabaja con nosotros"
                show={this.props.show}
                handleCancel={this.handleClose}
                handleConfirm={this.handleConfirm}
            >
                {this.renderModalBody()}
            </Modal>
        )
    }
}

export default WorkWithUsModal;