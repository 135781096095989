import React, { Component, Fragment } from 'react';
import TagsInput from 'react-tagsinput';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import Switch from "react-switch";
import {Collapse} from 'react-collapse';
import DateRangePicker from 'react-daterange-picker';
import Moment from 'moment';
import 'moment/locale/es';
import { extendMoment } from 'moment-range';
import { FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import ApiPickup from './ApiPickup';

import ModalForm from '@/components/ModalForm';
import PickupConfirmModal from '@/components/Modal/ConfirmModal';
import InfoModal from '@/components/Modal/InfoModal';
import PickupSummary from './PickupSummary';
import CertificateSummary from './CertificateSummary';
import MaterialsSelector from './MaterialsSelector';

import {STREET_TYPES, CITIES, phone, MATERIALS} from '@/globals';

import 'react-tagsinput/react-tagsinput.css';
import 'react-daterange-picker/dist/css/react-calendar.css'

import styles from './PickupModal.css';

const moment = extendMoment(Moment);
moment.locale('es');

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const TAGS_DELIMITERS      = [188, 32, 13, 189, 109, 111];
const TAGS_DELIMITERS_REG = /[;,\s\-/]+/;
const CORRECT_TAGS_INPUT_MSG = "Añadir numeración de saco";

class PickupModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pickup: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                streetType: 'calle',
                street: '',
                number: '',
                city: Object.keys(CITIES).sort()[0].toLowerCase(),
                pc: undefined,
                province: 'Madrid',
                bagsIds: [],
                comments: '',
            },
            tagsInputMsg: "Añadir numeración de saco",
            wantCertificate: false,
            certificateFileValidationLabel: "",
            certificate: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                dateRange: moment.range(
                    (new Date()).setHours(0,0,0,0),
                    (new Date()).setHours(0,0,0,0)
                ),
                workData: {
                    description: "",
                    streetType: 'calle',
                    street: '',
                    number: '',
                    pc: undefined,
                    city: Object.keys(CITIES).sort()[0].toLowerCase(),
                    province: "Madrid",
                    licenceNumber: ""
                },
                licenceFile: [],
                producerData: {
                    name: "",
                    cif: "",
                    streetType: "calle",
                    street: "",
                    number: "",
                    pc: undefined,
                    city: "",
                },
                builderData: {
                    name: "",
                    cif: "",
                    streetType: "calle",
                    street: "",
                    number: "",
                    pc: undefined,
                    city: "",
                },
                producerBuilderEqual: true,
                materials: {},
                comments: ''
            },
            inputPolicy: false,
            confirmModalShowed: false,
            infoModalShowed: false,
            apiError: false
        };

        this.api = new ApiPickup();

        // File types validation properties
        this.fileTypesAllowed = {
            pdf: 'application/pdf',
            png: 'image/png',
            jpeg: 'image/jpeg'
        };

        this.maxFileSize = 15;
        this.maxTotalFileSize = this.maxFileSize;

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleBagIdsChange = this.handleBagIdsChange.bind(this);
        this.handleWantCertificate = this.handleWantCertificate.bind(this);
        this.handleDropLicence = this.handleDropLicence.bind(this);
        this.handleProducerBuilderEqual = this.handleProducerBuilderEqual.bind(this);
        this.handleDateRange = this.handleDateRange.bind(this);
        this.handleAddMaterial = this.handleAddMaterial.bind(this);
        this.handleRemoveMaterials = this.handleRemoveMaterials.bind(this);
        this.confirmPickup = this.confirmPickup.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.handlerApiResponse = this.handlerApiResponse.bind(this);
    }

    // Life cicle methods

    componentDidMount(){
        if (this.props.certifificateOnly){
            this.setState({
                wantCertificate: true
            })
        }
    }

    // handle methods

     handleClose() {
        this.props.handleClose();
     }

     handleConfirm() {
        if (this.validate()) {
            this.setState({
                confirmModalShowed: true
            });
        }
     }

     confirmPickup() {
        this.closeConfirmModal()

        const data = new FormData();
        this.state.certificate.licenceFile.forEach(
            (file, i) => data.append(`file${i}`, file, file.name))

        let payLoad = {
            certificate: {...this.state.certificate},
            wantCertificate: this.state.wantCertificate
        };

        if (!this.props.certifificateOnly) {
            payLoad.pickup = {
                ...this.state.pickup
            }

            payLoad['certificate']['name'] = payLoad['pickup']['name'];
            payLoad['certificate']['lastName'] = payLoad['pickup']['lastName'];
            payLoad['certificate']['email'] = payLoad['pickup']['email'];
            payLoad['certificate']['phone'] = payLoad['pickup']['phone'];
        }

        if (this.state.certificate.producerBuilderEqual) {
            payLoad['certificate']['builderData'] = payLoad['certificate']['producerData'];
        }

        if (this.state.wantCertificate || this.props.certifificateOnly) {
            payLoad.certificate.dateRange = {
                start: payLoad.certificate.dateRange.start.format(),
                end: payLoad.certificate.dateRange.end.format()
            }
        }

        delete payLoad.certificate.licenceFile;

        const serializedPayLoad = JSON.stringify(payLoad);

        data.append('data', serializedPayLoad)

        this.api.submitPickup(data, this.handlerApiResponse)
        this.handleClose();
     }

     closeConfirmModal() {
        this.setState({
            confirmModalShowed: false,
            apiError: false
        });
     }

     closeInfoModal() {
         this.setState({
             infoModalShowed: false
         });
     }

     handleFormChange(event) {
        event.persist();

        switch(event.target.name) {
            case 'Policy':
                    this.setState({
                        inputPolicy: event.target.checked
                    });
                    break;
            case 'wantCertificate':
                    this.setState({
                        wantCertificate: event.target.checked
                    });
                    break;
            default:
                let value = event.target.value;
                let name = event.target.name;
                let role = event.target.dataset.role;
                this.setState((prevState) => {
                    if (role === "pickup") {
                        return {
                            [role]: {
                                ...prevState[role],
                                [name]: value
                            }
                        }
                    } else {
                        let subrole = event.target.dataset.subrole;

                        return subrole ? {
                            [role]: {
                                ...prevState[role],
                                [subrole]: {
                                    ...prevState[role][subrole],
                                    [name]: value
                                }
                            }
                        } :
                        {
                            [role]: {
                                ...prevState[role],
                                [name]: value
                            }
                        }
                    }
                });
        }
     }

     handleWantCertificate(value) {
         this.setState({
             wantCertificate: value
         })
     }

     handleDropLicence(fileItems) {

        this.setState((prevState) => {
            return {
                certificate: {
                    ...prevState.certificate,
                    licenceFile: fileItems.map(fileItem => fileItem.file)
                }
            }
        });
    }

     handleProducerBuilderEqual(value) {
        this.setState((prevState) => {
            return {
                certificate: {
                    ...prevState.certificate,
                    producerBuilderEqual: value
                }
            }
        });
    }

     handleBagIdsChange(bagsIds) {

        // If bagId is added not check if is valid
        if (bagsIds.length < this.state.pickup.bagsIds.length) {
            this.setState((prevState) => {
                return {
                    tagsInputMsg: CORRECT_TAGS_INPUT_MSG,
                    pickup: {
                        ...prevState.pickup,
                        bagsIds: bagsIds
                    }
                }
            });
        } else {
            let newBagsIds = bagsIds.pop();

            newBagsIds = newBagsIds.split(TAGS_DELIMITERS_REG)

            const [correctBagIds, tagsInputMsg] = this.validateTags(newBagsIds);

            this.setState((prevState) => {
                return {
                    tagsInputMsg: tagsInputMsg,
                    pickup: {
                        ...prevState.pickup,
                        bagsIds: this.state.pickup.bagsIds.concat(correctBagIds)
                    }
                }
            });
        }
     }

     handleDateRange(dateRange) {

         this.setState({
            certificate: {
                ...this.state.certificate,
                dateRange: dateRange
            }
         })

     }

     handleAddMaterial(material, tons, m3) {
         this.setState((prevState) => {
            if (material in prevState.certificate.materials) {
                return {
                    certificate: {
                        ...prevState.certificate,
                        materials: {
                            ...prevState.certificate.materials,
                            [material]: {
                                tons: prevState.certificate.materials[material].tons + tons,
                                m3: prevState.certificate.materials[material].m3 + m3,
                                description: MATERIALS[material],
                            }
                        }
                    }
                }
            } else {
                return {
                    certificate: {
                        ...prevState.certificate,
                        materials: {
                            ...prevState.certificate.materials,
                            [material]: {
                                tons: tons,
                                m3: m3,
                                description: MATERIALS[material],
                            }
                        }
                    }
                }
            }
         });
         document.getElementById("materialValidation").hidden = true;
     }

     handleRemoveMaterials(event) {
        event.persist();

        const materialId = event.target.dataset.id;

        this.setState((prevState) => {

            delete prevState.certificate.materials[materialId]

            return {
                certificate: {
                    ...prevState.certificate,
                    materials: {
                        ...prevState.certificate.materials,
                    }
                }
            }
        });
     }

     handlerApiResponse(status, data) {
        const apiError = status >= 400

        this.setState({
            infoModalShowed: true,
            apiError: apiError
        });
    }

    // Validations

    validate() {
        return (
            this.validateBagsIds() &&
            this.validateLicenceFile() &&
            this.validateMaterialBreakDown()
        )
    }

    validateTags(bagsIds) {

        const currentBagsIds = this.state.pickup.bagsIds;

        const newBagsIds = bagsIds.reduce((ac, id) => {

            if (id === "") {
                return ac;
            }

            const isNumber = /^[0-9]+$/.test(id);
            const isUnique = currentBagsIds.indexOf(id) === -1;

            if (isNumber && isUnique) {
                ac.correct.push(id);
            } else {
                ac.incorrect.push(id);
            }

            return ac;

        }, {
            correct: [],
            incorrect: []
        });

        const tagsInputMsg = newBagsIds.incorrect.length > 0 ?
                                `Numeración incorrecta ${newBagsIds.incorrect.join(", ")}` :
                                CORRECT_TAGS_INPUT_MSG

        return [newBagsIds.correct, tagsInputMsg]
    }

    validateBagsIds() {

        if (this.props.certifificateOnly) {
            return true
        }

        let bagsIdsContainer = document.getElementsByClassName("react-tagsinput")[0];
        if (!this.state.pickup.bagsIds.length > 0) {
            bagsIdsContainer.classList.add(styles.tagsInputContainerInvalid);
            return false;
        } else {
            bagsIdsContainer.classList.remove(styles.tagsInputContainerInvalid);
            return true
        }
    }

    validateLicenceFile() {

        let anyFileAdded = document.querySelectorAll(".filepond--item").length > 0;

        if (!anyFileAdded && this.state.wantCertificate) {
            this.setState({
                certificateFileValidationLabel: "Debes adjuntar algún archivo"
            })

            return anyFileAdded;
        }

        let allFilesItems = document.querySelectorAll(".filepond--item[data-filepond-item-state=load-invalid]");

        const validFiles = allFilesItems.length === 0;

        if (!validFiles && this.state.wantCertificate) {
            this.setState({
                certificateFileValidationLabel: "Algún archivo adjunto no es válido"
            })
        }

        return !this.state.wantCertificate || (validFiles && anyFileAdded);
    }

    validateMaterialBreakDown() {
        const materialValid = Object.keys(this.state.certificate.materials).length > 0;

        if (!materialValid && this.state.wantCertificate) {
            document.getElementById("materialValidation").hidden = false;
        } else {
            document.getElementById("materialValidation").hidden = true;
        }

        return materialValid || !this.state.wantCertificate
    }

    // methods

    allowedFileTypesLabel() {

        const [firstType, ...nextTypes] = Object.keys(this.fileTypesAllowed);

        const alloweFilesTypesStrMap =  '{' + nextTypes.reduce(
            (acc, key) => `${acc}, '${this.fileTypesAllowed[key]}': '.${key}'`,
            `'${this.fileTypesAllowed[firstType]}': '.${firstType}'`
        ) + '}';

        return alloweFilesTypesStrMap

    }

    // Render methods

    renderContact() {
        return (
            <Fragment>
                <small className="form-text text-muted">Contacto:</small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="name"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Nombre"
                                value={this.state.pickup.name}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="lastName"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Apellidos"
                                value={this.state.pickup.lastName}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="email"
                                name="email"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Email de contacto"
                                value={this.state.pickup.email}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="phone"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Teléfono"
                                value={this.state.pickup.phone}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderAddress() {
        return (
            <Fragment>
                <small className="form-text text-muted">Dirección de recogida</small>
                <Form.Row>
                    <Col xs={5} lg={3}>
                        <Form.Group>
                            <Form.Control as="select"
                                className={styles.pickupSelect}
                                title="Tipo de vía"
                                name="streetType"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Tipo de via"
                                onChange={this.handleFormChange}
                            >
                                <StreetTypeOptions selected={this.state.pickup.streetType} />
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={7} lg={7}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="street"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Nombre de la vía"
                                value={this.state.pickup.street}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3} lg={2}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="number"
                                placeholder="Nº"
                                data-role="pickup"
                                value={this.state.pickup.number}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={9} lg={5}>
                        <Form.Group>
                            <Form.Control as="select"
                                className={styles.pickupSelect}
                                title="Seleccione ciudad"
                                name="city"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Ciudad"
                                onChange={this.handleFormChange}
                            >
                                <CityOptions selected={this.state.pickup.city} />
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} lg={2}>
                        <Form.Group>
                            <Form.Control type="number"
                                name="pc"
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="CP"
                                value={this.state.pickup.pc}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={8} lg={5}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="province"
                                disabled
                                required={!this.props.certifificateOnly}
                                data-role="pickup"
                                placeholder="Provincia"
                                value={this.state.pickup.province}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderBagsIds() {
        return (
            <Fragment>
                <small className="form-text text-muted"
                    title="Números que aparecen en los sacos"
                >
                Numeración de los sacos</small>
                <TagsInput id="bags-ids-input"
                    title="Números que aparecen en los sacos"
                    value={this.state.pickup.bagsIds}
                    addKeys={TAGS_DELIMITERS}
                    onChange={this.handleBagIdsChange}
                    addOnBlur={true}
                    className={`react-tagsinput form-control ${styles.tagsInputContainer}`}
                    focusedClassName={`react-tagsinput--focused form-control ${styles.tagsInputContainerFocused}`}
                    inputProps={{
                            placeholder: this.state.tagsInputMsg,
                            className: this.state.tagsInputMsg === CORRECT_TAGS_INPUT_MSG ?
                                            `react-tagsinput-input ${styles.tagsInputInput}` :
                                            `react-tagsinput-input ${styles.tagsInputIncorrectInput}`
                    }}
                    tagProps={{
                        className: `react-tagsinput-tag ${styles.tagsInputTags}`
                    }}
                />
                <small className={`form-text text-muted ${styles.tagsInputError}`}>
                    {this.state.tagsInputError}
                </small>
            </Fragment>
        )
    }

    renderComments() {
        return (
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Control as="textarea"
                            name="comments"
                            cols="40"
                            rows="5"
                            data-role="pickup"
                            placeholder="Comentarios"
                            value={this.state.pickup.comments}
                            onChange={this.handleFormChange}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
        )
    }

    renderPickup() {
        return (
            <Fragment>
                {this.renderContact()}
                {this.renderAddress()}
                {this.renderBagsIds()}
                {this.renderComments()}
            </Fragment>
        )
    }

    renderPickupInfo() {

        let sucess, error;

        if (this.props.certifificateOnly) {
            sucess = `Hemos recibido el trámite de su certificado. En breve recibirá un email con los
                      datos del certificado`;
            error = `Ha ocurrido un error en el servidor y no se garantiza que
                     el trámite del certificado haya sido recibido. Por favor antes de tramitar otro certificado
                     llame a <a href="tel:${phone}">${phone}</a> para confirmar si ha sido recibido o no.`;
        } else {
            sucess = `Hemos recibido su recogida. En breve recibirá un email con los datos de la recogida`;
            error = `Ha ocurrido un error en el servidor y no se garantiza que
                     la recogida haya sido recibida. Por favor antes de programar otra recogida
                     llame a <a href="tel:${phone}">${phone}</a> para confirmar si ha sido recibido o no.`;
        }

        let message = this.state.apiError ? error : sucess;

        return (
            <p dangerouslySetInnerHTML={{__html: message}}></p>
        )
    }

    // Certificate render methods

    renderCertificateContact() {
        return this.props.certifificateOnly ? (
            <CertificateSection name="Datos de contacto">
                <small className="form-text text-muted">Contacto:</small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="name"
                                required={this.props.certifificateOnly}
                                data-role="certificate"
                                placeholder="Nombre"
                                value={this.state.certificate.name}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="lastName"
                                required={this.props.certifificateOnly}
                                data-role="certificate"
                                placeholder="Apellidos"
                                value={this.state.certificate.lastName}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="email"
                                name="email"
                                required={this.props.certifificateOnly}
                                data-role="certificate"
                                placeholder="Email de contacto"
                                value={this.state.certificate.email}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validationCustom03">
                            <Form.Control type="text"
                                name="phone"
                                required={this.props.certifificateOnly}
                                data-role="certificate"
                                placeholder="Teléfono"
                                value={this.state.certificate.phone}
                                onChange={this.handleFormChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Inserte un telefono válido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </CertificateSection>
        ) : ""
    }

    renderWantCertificate() {
        return (
            <Form.Row hidden={this.props.certifificateOnly}>
                <Col>
                    <Switch
                        onChange={this.handleWantCertificate}
                        checked={this.state.wantCertificate || this.props.certifificateOnly}
                        uncheckedIcon={<div className={`${styles.switchContent} ${styles.switchChecked}`}>No</div>}
                        checkedIcon={<div className={`${styles.switchContent} ${styles.switchChecked}`}>Si</div>}
                        className={`react-switch ${styles.switch}`}
                        onColor="#ff4db2"
                        offColor="#808080"
                    /><span>Quiero tramitar el certificado del vertedero</span>
                </Col>
            </Form.Row>
        )
    }

    renderCertificateAlert() {
        if (this.state.wantCertificate) {
            return (
                <div className="alert alert-primary text-left" >
                    <h5>Importante!</h5>
                    <p className="text-left">
                        El certificado del vertedero solo hay que pedirlo una sola vez. Si prevee que va a usar
                        más sacos, solo debe gestionar el certificado en la última recogida. La fecha de inicio
                        será la fecha de recogida del primer saco, y la fecha de fin, la fecha de recogida del
                        último saco.
                    </p>
                </div>
            )
        } else {
            return ""
        }
    }

    renderDateInterval() {
        return (
            <CertificateSection name="Periodo de la obra">
                <Form.Row>
                    <Col>
                        <Form.Row>
                            <Form.Group className={styles.certificateCalendarInputsContainer}>
                                <small className="form-text text-muted"
                                    title="Fecha de inicio"
                                >
                                    Fecha de inicio
                                </small>
                                <Form.Control type="text"
                                    name="Work start"
                                    required={this.state.wantCertificate}
                                    disabled
                                    data-role="certificate"
                                    data-subrole="dateRange"
                                    placeholder="Inicio de la obra"
                                    value={
                                        this.state.certificate.dateRange.start ?
                                            this.state.certificate.dateRange.start.format('LL') :
                                            ""
                                    }
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={styles.certificateCalendarInputsContainer}>
                                <small className="form-text text-muted"
                                        title="Fecha de fin"
                                    >
                                        Fecha de fin
                                </small>
                                <Form.Control type="text"
                                    name="Work end"
                                    required={this.state.wantCertificate}
                                    disabled
                                    data-role="certificate"
                                    data-subrole="dateRange"
                                    placeholder="Fin de la obra"
                                    value={
                                        this.state.certificate.dateRange.end ?
                                            this.state.certificate.dateRange.end.format('LL') :
                                            ""
                                    }
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Col>
                        <DateRangePicker
                            onSelect={this.handleDateRange}
                            firstOfWeek={1}
                            singleDateRange={1}
                            locale='es'
                            numberOfCalendars={1}
                            value={this.state.certificate.dateRange}
                        />
                    </Col>
                </Form.Row>
            </CertificateSection>
        );
    }

    renderWorkData() {
        return (
            <CertificateSection name="Datos de la obra">
                <small className="form-text text-muted"
                    title="Indique cual es el motivo de la obra, una reforma, acondicionamiento de local, obra menor..."
                >
                    Descripción de la obra
                </small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                title="Indique cual es el motivo de la obra, una reforma, acondicionamiento de local, obra menor..."
                                name="description"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Descripción de la obra"
                                value={this.state.certificate.workData.description}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <small className="form-text text-muted">Dirección de la obra</small>
                <Form.Row>
                    <Col xs={5} lg={3}>
                        <Form.Group>
                            <Form.Control as="select"
                                className={styles.pickupSelect}
                                title="Tipo de vía"
                                name="streetType"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Tipo de via"
                                onChange={this.handleFormChange}
                            >
                                <StreetTypeOptions selected={this.state.certificate.workData.streetType} />
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={7} lg={7}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="street"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Nombre de la vía"
                                value={this.state.certificate.workData.street}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3} lg={2}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="number"
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Nº"
                                value={this.state.certificate.workData.number}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={9} lg={5}>
                        <Form.Group>
                            <Form.Control as="select"
                                className={styles.pickupSelect}
                                title="Seleccione ciudad"
                                name="city"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Ciudad"
                                onChange={this.handleFormChange}
                            >
                                <CityOptions selected={this.state.certificate.workData.city} />
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} lg={2}>
                        <Form.Group>
                            <Form.Control type="number"
                                name="pc"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="CP"
                                value={this.state.certificate.workData.pc}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={8} lg={5}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="province"
                                disabled
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Provincia"
                                value={this.state.certificate.workData.province}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <small className="form-text text-muted"
                    title="Número de licencia municipal"
                >
                    Número de licencia municipal
                </small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="licenceNumber"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="workData"
                                placeholder="Número de licencia"
                                value={this.state.certificate.workData.licenceNumber}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </CertificateSection>
        )
    }

    renderLicenceFile() {
        return (
            <CertificateSection name="Licencia de obra">
                <Container>
                    <Row>
                        <Col>
                            <FilePond
                                ref={ref => (this.pond = ref)}
                                files={this.state.certificate.licenceFile}
                                allowMultiple={false}
                                maxFiles={1}
                                labelIdle="Arrastra aquí una copia de la Licencia de obra"
                                onupdatefiles={this.handleDropLicence}
                                // File size validation
                                allowFileSizeValidation={true}
                                maxFileSize={`${this.maxFileSize}MB`}
                                maxTotalFileSize={`${this.maxTotalFileSize}MB`}
                                labelMaxFileSize={'Ha excedido el tamaño máximo para el archivo. Tamaño máximo permitido {filesize}'}
                                labelMaxFileSizeExceeded={'El archivo excede el tamaño máximo'}
                                labelMaxTotalFileSize={'Ha excedido el tamaño máximo total. Tamaño máximo total permitido {filesize}'}
                                labelMaxTotalFileSizeExceeded={'Se ha excedido el tamaño máximo total'}
                                // File type validation
                                allowFileTypeValidation={true}
                                acceptedFileTypes={Object.values(this.fileTypesAllowed)}
                                labelFileTypeNotAllowed="El tipo de archivo no está permitido"
                                fileValidateTypeLabelExpectedTypes="Tipos de archivo permitidos {allButLastType} or {lastType}"
                                fileValidateTypeLabelExpectedTypesMap={this.allowedFileTypesLabel()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.certificateFileValidationLabel}>
                            {this.state.certificateFileValidationLabel}
                        </Col>
                    </Row>
                </Container>
            </CertificateSection>
        )
    }

    renderProducerData() {
        return (
            <CertificateSection name="Datos del productor/promotor">
                <small className="form-text text-muted"
                    title="Nombre o razón social"
                >
                    Identificación
                </small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                autoComplete="off"
                                title="Nombre"
                                name="name"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="Nombre/Razón social"
                                value={this.state.certificate.producerData.name}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                title="CIF"
                                name="cif"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="CIF"
                                value={this.state.certificate.producerData.cif}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <small className="form-text text-muted">Dirección</small>
                <Form.Row>
                    <Col xs={5} lg={3}>
                        <Form.Group>
                            <Form.Control as="select"
                                className={styles.pickupSelect}
                                title="Tipo de vía"
                                name="streetType"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="Tipo de via"
                                onChange={this.handleFormChange}
                            >
                                <StreetTypeOptions selected={this.state.certificate.producerData.streetType} />
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={7} lg={7}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="street"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="Nombre de la vía"
                                value={this.state.certificate.producerData.street}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={3} lg={2}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="number"
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="Nº"
                                value={this.state.certificate.producerData.number}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={9} lg={5}>
                        <Form.Group>
                            <Form.Control type="text"
                                className={styles.pickupSelect}
                                title="Indique la ciudad"
                                name="city"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="Ciudad"
                                value={this.state.certificate.producerData.city}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} lg={2}>
                        <Form.Group>
                            <Form.Control type="number"
                                name="pc"
                                required={this.state.wantCertificate}
                                data-role="certificate"
                                data-subrole="producerData"
                                placeholder="CP"
                                value={this.state.certificate.producerData.pc}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </CertificateSection>
        )
    }

    renderProducerBuilderEqual() {
        return (
            <Fragment>
                <Form.Row className={styles.ProducerBuilderCheck}>
                    <Col>
                        <span>Productor y constructor son los mismos  </span>
                        <Switch
                            onChange={this.handleProducerBuilderEqual}
                            checked={this.state.certificate.producerBuilderEqual}
                            uncheckedIcon={<div className={`${styles.switchContent} ${styles.switchChecked}`}>No</div>}
                            checkedIcon={<div className={`${styles.switchContent} ${styles.switchChecked}`}>Si</div>}
                            className={`react-switch ${styles.switch}`}
                            onColor="#ff4db2"
                            offColor="#808080"
                        />
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderBuilderData() {
        return (
            <Collapse
                theme={
                    {
                        collapse: styles.BuilderSectionCollapseContainer,
                    }
                }
                isOpened={!this.state.certificate.producerBuilderEqual}
            >
                <CertificateSection name="Datos del constructor/poseedor">
                    <small className="form-text text-muted"
                        title="Nombre o razón social"
                    >
                        Identificación
                    </small>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Control type="text"
                                    autoComplete="off"
                                    title="Nombre"
                                    name="name"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="Nombre/Razón social"
                                    value={this.state.certificate.builderData.name}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Control type="text"
                                    title="CIF"
                                    name="cif"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="CIF"
                                    value={this.state.certificate.builderData.cif}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <small className="form-text text-muted">Dirección</small>
                    <Form.Row>
                        <Col xs={5} lg={3}>
                            <Form.Group>
                                <Form.Control as="select"
                                    className={styles.pickupSelect}
                                    title="Tipo de vía"
                                    name="streetType"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="Tipo de via"
                                    onChange={this.handleFormChange}
                                >
                                    <StreetTypeOptions selected={this.state.certificate.builderData.streetType} />
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={7} lg={7}>
                            <Form.Group>
                                <Form.Control type="text"
                                    name="street"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="Nombre de la vía"
                                    value={this.state.certificate.builderData.street}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={3} lg={2}>
                            <Form.Group>
                                <Form.Control type="text"
                                    name="number"
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="Nº"
                                    value={this.state.certificate.builderData.number}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={9} lg={5}>
                            <Form.Group>
                                <Form.Control type="text"
                                    className={styles.pickupSelect}
                                    title="Indique la ciudad"
                                    name="city"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="Ciudad"
                                    onChange={this.handleFormChange}
                                    value={this.state.certificate.builderData.city}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4} lg={2}>
                            <Form.Group>
                                <Form.Control type="number"
                                    name="pc"
                                    required={this.state.wantCertificate && !this.state.certificate.producerBuilderEqual}
                                    data-role="certificate"
                                    data-subrole="builderData"
                                    placeholder="CP"
                                    value={this.state.certificate.builderData.pc}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </CertificateSection>
            </Collapse>
        )
    }

    renderProducerBuilder() {
        return (
            <Fragment>
                {this.renderProducerData()}
                {this.renderProducerBuilderEqual()}
                {this.renderBuilderData()}
            </Fragment>
        )
    }

    renderMaterialBreakDown() {
        return (
            <CertificateSection name="Desglose de materiales">
                <MaterialsSelector addMaterials={this.handleAddMaterial} />
                <MaterialsBreakDown
                    materials={this.state.certificate.materials}
                    removeMaterials={this.handleRemoveMaterials}
                />
            </CertificateSection>
        )
    }

    renderCertificateComment() {
        return (
            <CertificateSection name="Comentarios">
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control as="textarea"
                                name="comments"
                                cols="40"
                                rows="5"
                                data-role="certificate"
                                placeholder="Comentarios"
                                value={this.state.certificate.comments}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </CertificateSection>
        )
    }

    renderCertificate() {
        return (
            <Fragment>
                {this.renderWantCertificate()}
                {this.renderCertificateAlert()}
                <Collapse theme={{
                        collapse: styles.certificateContainer,
                        content: styles.certificateContent
                    }}
                    isOpened={this.state.wantCertificate}
                >
                    <span className={styles.certificateTitle}>
                        Datos del certificado
                    </span>
                    {this.renderCertificateContact()}
                    {this.renderDateInterval()}
                    {this.renderWorkData()}
                    {this.renderLicenceFile()}
                    {this.renderProducerBuilder()}
                    {this.renderMaterialBreakDown()}
                    {this.renderCertificateComment()}
                </Collapse>
            </Fragment>
        )
    }

    renderSummary() {
        if (this.props.certifificateOnly) {
            return (
                <Fragment>
                    <CertificateSummary certificate={this.state.certificate} />
                </Fragment>
            )
        } else {

            let summaryContent;

            if (this.state.wantCertificate) {
                summaryContent = [
                    <PickupSummary key={0} pickup={this.state.pickup} />,
                    <hr key={1} style={{borderColor: "var(--grey-100)"}} />,
                    <span key={2} style={{fontSize: "2em"}}>Datos del certificado</span>,
                    <CertificateSummary key={3} certificate={this.state.certificate} />
                ]
            } else {
                summaryContent = <PickupSummary pickup={this.state.pickup} />
            }

            return (
                <Fragment>
                    {summaryContent}
                </Fragment>
            )
        }
    }

    render() {

        let errorMsg = this.props.certifificateOnly ? "Recuerde validar" : "Recuerde validar que ha añido la numeración de los sacos,"
        errorMsg = this.state.wantCertificate ?
            `${errorMsg} que ha adjuntado la licencia de obra y que ha añadido materiales a la lista` :
            errorMsg

        return (
            <Fragment>
                <ModalForm title={
                        this.props.certifificateOnly ?
                            "Tramitar un certificado del vertedero" :
                            "Programar una recogida"}
                    show={this.props.show}
                    handleCancel={this.handleClose}
                    handleConfirm={this.handleConfirm}
                    policy={this.state.inputPolicy}
                    policyHandler={this.handleFormChange}
                    errorMsg={errorMsg}
                >
                    <div hidden={this.props.certifificateOnly}>
                        {this.renderPickup()}
                    </div>
                    {this.renderCertificate()}
                </ModalForm>
                <PickupConfirmModal title={
                        this.props.certifificateOnly ?
                            "Confirmación del certificado" :
                            "Confirmación de la recogida"}
                    show={this.state.confirmModalShowed}
                    handleCancel={this.closeConfirmModal}
                    handleConfirm={this.confirmPickup}
                >
                    {this.renderSummary()}
                </PickupConfirmModal>
                <InfoModal title={
                        this.props.certifificateOnly ?
                            "Información del certificado" :
                            "Información de la recogida"}
                    show={this.state.infoModalShowed}
                    handleClose={this.closeInfoModal}>
                    {this.renderPickupInfo()}
                </InfoModal>
            </Fragment>
        )
    }
}

function StreetTypeOptions(props) {
    return STREET_TYPES.sort().map((streetType) => {
        return <option key={streetType}
                    value={streetType.toLowerCase()}
                    selected={props.selected === streetType.toLowerCase()}
                >
                    {streetType}
                </option>
    });
}

function CityOptions(props) {
    return Object.keys(CITIES).sort().map((city) => {
        return <option key={city}
                    value={city.toLowerCase()}
                    selected={props.selected === city.toLowerCase()}
                >
                    {city}
                </option>
    });
}

function CertificateSection(props) {
    return (
        <Fragment>
            <div className={styles.certificateSection}>
                <span className={styles.certificateSectionTitle}>{props.name}</span>
                {props.children}
            </div>
        </Fragment>
    )
}

function MaterialsBreakDown(props) {

    const materialsRegisters = Object.keys(props.materials).map((id) => {
        return (
            <Row key={id} className={styles.MaterialsBreakDownRow}>
                <Col xs={7} lg={7}>
                    {MATERIALS[id]}
                </Col>
                <Col xs={1} lg={2}>
                    {props.materials[id].tons}
                </Col>
                <Col xs={1} lg={2}>
                    {props.materials[id].m3}
                </Col>
                <Col xs={2} lg={1}>
                    <Button title="Eliminar vertido"
                        className={styles.removeMaterialButton}
                        data-id={id}
                        onClick={props.removeMaterials}
                        >-</Button>
                </Col>
            </Row>
        )
    });

    let header = materialsRegisters.length > 0 ? (
        <div>
            <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>Materiales agregados</p>
            <hr style={{borderColor: "var(--grey-100)", margin: '0 auto', width: '95%'}} />
            <Row className={styles.MaterialsBreakDownHeader}>
                <Col xs={7} lg={7}>
                    <small>Material</small>
                </Col>
                <Col xs={1} lg={2}>
                    <small>T</small>
                </Col>
                <Col xs={1} lg={2}>
                    <small>m³</small>
                </Col>
                <Col xs={2} lg={1}>
                    <small>Eliminar</small>
                </Col>
            </Row>
        </div>
    ) : <div></div>

    return (
        <div>
            {header}
            {materialsRegisters}
        </div>
    )
}

export default PickupModal;
