
import React, { Component, Fragment } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { MATERIALS } from '@/globals';

import styles from './MaterialsSelector.css';

class MaterialsSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      material: Object.keys(MATERIALS).sort()[0],
      tons: 0,
      m3: 0,
      valid: true
    }

    this.handleMaterial = this.handleMaterial.bind(this);
    this.handleAddMaterial = this.handleAddMaterial.bind(this);
  }

  handleMaterial(event) {
    event.persist();

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    })
  }

  handleAddMaterial() {

    if (parseFloat(this.state.tons) + parseFloat(this.state.m3) > 0) {
      const {material, tons, m3} = this.state;
      this.props.addMaterials(material, parseFloat(tons), parseFloat(m3));
      this.setState({
        valid: true
      });
    } else {
      this.setState({
        valid: false
      });
    }
  }

  renderAlertMessage() {
    return (this.state.valid ? null :
      <small className={styles.alertMessage}>
        Debes poner alguna cantidad en toneladas o m³
      </small>
    )
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <div className="alert alert-primary text-left" >
              <h5>Información!</h5>
              <p className="text-left">
                  Debe indicar el tipo de material vertido y por lo menos rellenar
                  las toneladas o los m³. Para agregarlo a la lista de materiales
                  pinche en el botón +
                  <br/>Recuerde que un saco tiene 1 m³
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={7}>
            <small className="form-text text-muted"
                title="Seleccione un material vertido"
            >
                Selección de materiales vertidos
            </small>
            <Form.Group>
              <Form.Control as="select"
                className={styles.materialsSelect}
                title="Seleccione materiales vertidos"
                name="material"
                placeholder="Materiales"
                onChange={this.handleMaterial}
              >
                <MaterialOptions selected={this.state.material} />
              </Form.Control>
            </Form.Group>
            <small id="materialValidation" className={styles.alertMessage} hidden>
              Debes añadir algún material
            </small>
          </Col>
          <Col xs={10} lg={4}>
            <Row>
              <Col xs={6}>
                <small className="form-text text-muted"
                    title="Indique las toneladas"
                >
                    Toneladas
                </small>
                <Form.Group>
                  <Form.Control type="number"
                    className={styles.quantityInput}
                    name="tons"
                    placeholder="toneladas"
                    min="0"
                    step="0.25"
                    value={this.state.tons}
                    onChange={this.handleMaterial}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <small className="form-text text-muted"
                      title="Indique los metros cúbicos"
                  >
                      m³
                </small>
                <Form.Group>
                  <Form.Control type="number"
                    className={styles.quantityInput}
                    name="m3"
                    min="0"
                    step="1"
                    placeholder="m³"
                    value={this.state.m3}
                    onChange={this.handleMaterial}
                  />
                </Form.Group>
              </Col>
              {this.renderAlertMessage()}
            </Row>
          </Col>
          <Col className={styles.ButtonContainer} xs={2} lg={1}>
            <Button title="Agregar vertido"
              className={styles.addButton}
              onClick={this.handleAddMaterial}
            >+</Button>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

function MaterialOptions(props) {
  return Object.keys(MATERIALS).sort().map((materialId) => {
    return <option key={materialId}
      value={materialId}
      selected={props.selected === materialId}
    >
      {MATERIALS[materialId]}
    </option>
  });
}

export default MaterialsSelector;