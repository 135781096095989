import Api from './index';

/**
 * Callback that will be called of the Api instance.
 *
 * @callback    apiCallback
 * @param       {int}    http code status returned
 * @param       {Object} data Data replied for the api
 */

class ApiHandler {

    constructor() {
        this.api = new Api();
    }

    /**
     * This method call a defined method of Api object, in specific endpoint
     *
     * @param {apiCallback}    method     Method of Api instance that will be called
     * @param {string}         endpoint   Endpoint where the method will do the request
     * @param {...any}         args       arguments that need method Api. You can see in Api class, method doc
     */
    apiHandler(method, endpoint, ...args) {
        if (this.api.ready) {
            method(this.api.endpoints[endpoint], ...args);
        } else {
            alert("El servidor no esta disponible. Intentelo más tarde");
        }
    }
}

export default ApiHandler;