import React, { Component } from 'react';
import Modal from '../InfoModal';
import marked from "marked";

// Assets
import styles from './TermsModal.css';
import terms from './terms.md';

class TermsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            termsText: null
        };
    }

    componentDidMount() {
        fetch(terms)
            .then(response => {
                return response.text();
            })
            .then(text => {
            this.setState({
                termsText: marked(text)
            });
        });
    }

    render() {

        const { termsText } = this.state;

        return (
            <Modal
                show={this.props.show}
                handleClose={this.props.handleClose}
                title="Aviso Legal"
            >
                <div className={styles.termsContainer}
                    dangerouslySetInnerHTML={{__html: termsText}}
                ></div>
            </Modal>
        )
    }
}

export default TermsModal;