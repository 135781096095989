import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

// Assets
import styles from './CustomForm.css';

 class PrivacyCheckbox extends Component {

    render() {
        return (
            <Form.Group className={styles.privacyCheck}>
                <Form.Check type="checkbox"
                    required
                    name="Policy"
                    label="He leído y acepto el aviso legal y la política de privacidad"
                    feedback="Debes leer y aceptar el aviso legal y la política de privacidad"
                    onChange={this.props.handler}
                    checked={this.props.checked}
                >
                </Form.Check>
            </Form.Group>
        )
    }
}

export default PrivacyCheckbox;