import React, { Component } from 'react';
import Modal from '../InfoModal';
import marked from "marked";

// Assets
import styles from './GRPDModal.css';
import grpd from './privacity.md';

class GRPDModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            grpdText: null
        };
    }

    componentDidMount() {
        fetch(grpd)
            .then(response => {
                return response.text();
            })
            .then(text => {
            this.setState({
                grpdText: marked(text)
            });
        });
    }

    render() {

        const { grpdText } = this.state;

        return (
            <Modal
                show={this.props.show}
                handleClose={this.props.handleClose}
                title="Política de Privacidad"
            >
                <div className={styles.grpdContainer}
                    dangerouslySetInnerHTML={{__html: grpdText}}
                ></div>
            </Modal>
        )
    }
}

export default GRPDModal;