import React, { Component, Fragment } from 'react';

// Components
import { Container, Row, Col } from 'react-bootstrap';
import GRPDModal from '@/components/Modal/GRPDModal';
import TermsModal from '@/components/Modal/TermsModal';
import CookiesModal from '@/components/Modal/CookiesModal';
import WorkWithUsModal from '@/components/Modal/WorkWithUsModal';

// Import assets
import styles from './Footer.css';

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            termsModalShowed: false,
            grpdModalShowed: false,
            cookiesModalShowed: false,
            employmentModalShowed: false
        };

        this.phone = '677528478';
        this.facebook = 'sacoslosgemelos1m';
        this.instagram = 'sacoslosgemelos';
        this.email = 'info@sacoslosgemelos.com';

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    // Handlers

    showModal(event){
        let modal = `${event.target.dataset.modal}ModalShowed`;
        this.setState({[modal]: true});
    }

    closeModal(event) {
        this.setState({
            termsModalShowed: false,
            grpdModalShowed: false,
            cookiesModalShowed: false,
            employmentModalShowed: false,
        });
    }

    // Render Methods

    renderFooter() {
        return (
            <Container>
                <Row>
                    <Col>
                        <FooterLinks showModal={this.showModal} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FooterActions
                            phone={this.phone}
                            email={this.email}
                            facebook={this.facebook}
                            instagram={this.instagram}/>
                    </Col>
                </Row>
                <div></div>
            </Container>
        )
    }

    render() {

        return (
            <Fragment>
                <footer className={`${styles.footerFour} ${styles.footerDark}`}>
                    {this.renderFooter()}
                </footer>
                <GRPDModal show={this.state.grpdModalShowed} handleClose={this.closeModal} />
                <TermsModal show={this.state.termsModalShowed} handleClose={this.closeModal} />
                <CookiesModal show={this.state.cookiesModalShowed} handleClose={this.closeModal} />
                <WorkWithUsModal show={this.state.employmentModalShowed} handleClose={this.closeModal} />
            </Fragment>
        )
    }
}

function FooterLinks(props) {
    return (
        <ul id="menu-footer" className={styles.footerMenu}>
            <li className="nav-item"><a className="nav-link" href="#home">Home</a>
            </li>
            <li className="nav-item"><a href="#null" data-modal="terms" onClick={props.showModal}>Aviso legal</a>
            </li>
            <li className="nav-item"><a href="#null" data-modal="grpd" onClick={props.showModal}>Política Privacidad</a>
            </li>
            <li className="nav-item"><a href="#null" data-modal="cookies" onClick={props.showModal}>Política de Cookies</a>
            </li>
            <li className="nav-item"><a href="#null" data-modal="employment" onClick={props.showModal}>Empleo</a>
            </li>
        </ul>
    )
}

function FooterActions(props) {
    return (
        <ul className={styles.footerSoc}>
            <li>
                <a className={styles.phoneColor} href={`tel:${props.phone}`}><i className="fa fa-phone"></i></a>
            </li>
            <li>
                <a className={styles.whatsappColor} href={`https://wa.me/34${props.phone}`} rel="noopener noreferrer" target="_blank"><i className="fa fa-whatsapp"></i></a>
            </li>
            <li>
                <a className={styles.facebookColor} href={`https://www.facebook.com/${props.facebook}`} rel="noopener noreferrer" target="_blank"><i className="fa fa-facebook"></i></a>
            </li>
            <li>
                <a className={styles.instagramColor} href={`https://www.instagram.com/${props.instagram}`} rel="noopener noreferrer" target="_blank"><i className="fa fa-instagram"></i></a>
            </li>
            <li>
                <a className={styles.mailColor} href={`mailto:${props.email}`}><i className="fa fa-envelope"></i></a>
            </li>
        </ul>
    )
}

export default Footer;