import React, { Component } from 'react';
import Modal from '../InfoModal';
import marked from "marked";

// Assets
import styles from './CookiesModal.css';
import cookies from './cookies.md';

class CookiesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cookiesText: null
        };
    }

    componentDidMount() {
        fetch(cookies)
            .then(response => {
                return response.text();
            })
            .then(text => {
            this.setState({
                cookiesText: marked(text)
            });
        });
    }

    render() {

        const { cookiesText } = this.state;

        return (
            <Modal
                show={this.props.show}
                handleClose={this.props.handleClose}
                title="Política de Cookies"
            >
                <div className={styles.cookiesContainer}
                    dangerouslySetInnerHTML={{__html: cookiesText}}
                ></div>
            </Modal>
        )
    }
}

export default CookiesModal;