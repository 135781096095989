import React, { Component } from "react"
import maintenance from "@/assets/maintenance.png";

import styles from "./Maintenance.css"

class Maintenance extends Component {

    componentDidMount() {
        document.getElementById("cover-section").setAttribute("hidden", "true")
        document.getElementById("aboutus-section").setAttribute("hidden", "true")
    }

    render() {
        return (
            <div className={ styles.maintenanceContainer }>
                <h2 className={ styles.maintenanceTitle }>Estamos haciendo labores de mantenimiento. Perdone las molestias</h2>
                <img src={maintenance} alt="En mantenimiento" />
            </div>
        )
    }
}

export default  Maintenance
