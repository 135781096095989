import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

// Components
import PrivacyCheckbox from '@/components/Form/PrivacyCheckbox';
import Modal from '@/components/Modal/ConfirmModal';
import FormErrorMsg from '@/components/FormErrorMsg';

// Assets
import styles from './ModalForm.css';

class ModalForm extends Component {

    /**
     * props description
     *
     * title            title of the modal
     * show             indicate if the modal is showed or hidden
     * handleCancel     handler when the modal is closed or cancel
     * handleConfirm    handler when the form is confirmed
     * policy           policy state of father component
     * policyHandler    handler to handle the policy check changes
     */
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
        };

        this.formId = Math.random().toString(36).substring(2, 12);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    // Handlers

    handleConfirm(event) {
        event.persist();
        event.preventDefault();
        event.stopPropagation();

        if (this.handleSubmit())
            this.props.handleConfirm(event)
    }

    handleCancel() {

        this.resetForm();

        this.props.handleCancel();
    }

    handleSubmit() {

        const form = document.getElementById(this.formId);

        if (form.checkValidity() === false || !this.props.policy) {
          this.setState({ validated: true });
          return false;
        }

        this.resetForm();

        return true;

      }

    // Methods

    resetForm() {
        this.setState({
            validated: false,
        });
    }

    // Reders

    render() {
        return (
            <Modal title={this.props.title}
                show={this.props.show}
                handleCancel={this.handleCancel}
                handleConfirm={this.handleConfirm}>
                <div className="modal-form">
                    <Form id={this.formId}
                        onSubmit={this.handleSubmit}
                        noValidate
                        validated={this.state.validated}
                        className={styles.customForm}
                    >
                        {this.props.children}
                        <PrivacyCheckbox handler={this.props.policyHandler} checked={this.props.policy}/>
                        <FormErrorMsg validated={this.state.validated} auxMsg={this.props.errorMsg} />
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default ModalForm;