import React, { Component } from 'react';
import styles from './Spinner.css';

class Spinner extends Component {

    render() {
        return (
            <div className={styles.loader}>
                <div className={styles.loaderOutter}></div>
                <div className={styles.loaderInner}></div>
            </div>
        )
    }
}

export default Spinner;
