import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';

class ConfirmModal extends Component {

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleCancel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.props.children}
                </Modal.Body>

                <Modal.Footer>
                    <Button data-action="confirm" onClick={this.props.handleConfirm}>
                        Aceptar
                    </Button>
                    <Button data-action="cancel" onClick={this.props.handleCancel}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmModal;