import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import styles from "./PickupSummary.css";

function PickupSummary(props) {

    return (<Container className={`${styles.PickupSummaryContainer}`}>
                <h4 className="grey-150">Datos de contacto:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Nombre: </b>{props.pickup.name}
                            </li>
                            <li>
                                <b>Apellidos: </b>{props.pickup.lastName}
                            </li>
                            <li>
                                <b>Email: </b>{props.pickup.email}
                            </li>
                            <li>
                                <b>Telefono: </b>{props.pickup.phone}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>{props.pickup.streetType}: </b>{props.pickup.street}
                            </li>
                            <li>
                                <b>Número: </b>{props.pickup.number}
                            </li>
                            <li>
                                <b>Población: </b>{props.pickup.city}
                            </li>
                            <li>
                                <b>CP: </b>{props.pickup.pc}
                            </li>
                            <li>
                                <b>Provincia: </b>{props.pickup.province}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Datos de la recogida:</h4>
                <Row>
                    <Col>
                        <ul>
                            <li>
                                <b>Número de sacos: </b>{props.pickup.bagsIds.length}
                            </li>
                            <li>
                                <b>Numeraciones de los sacos: </b> {props.pickup.bagsIds.join(', ')}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Comentarios</h4>
                <Row>
                    <p className={styles.PickupSummaryComment}>
                        {props.pickup.comments}
                    </p>
                </Row>
            </Container>)
}

export default PickupSummary;