import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Advices.css';
import { detect } from 'detect-browser';
import compareVersions from 'compare-versions';

class Advices extends Component {

    constructor(props){
        super(props);

        this.advices = [];

        toast.configure({
            autoClose: false,
            draggable: false,
            enableMultiContainer: true,
            className: styles.newsToast,
            toastClassName: 'pink-150'
        })

        this.notifyDelay = 3500;

        this.notify = this.notify.bind(this);

        this.navigatorCompatibilityChecker =
          this.navigatorCompatibilityChecker.bind(this);

        this.checkers = [
          this.navigatorCompatibilityChecker
        ]

    }

    // Advices Checkers

    navigatorCompatibilityChecker(){
      const browser = detect();

      const browserCategory = !["Android OS", "iOS"].includes(browser.os) ?
        'desktop' : 'mobile';

      const compatibility =  {
        "mobile": {
          "chrome": "49",
          "firefox": "41",
          "opera": "36",
          "safari": "8",
          "samsung": "5.0",
          "chromium-webview": "49"
        },
        "desktop": {
          "chrome": "49",
          "firefox": "50",
          "opera": "36",
          "safari": "10"
        }
      }

      const isCompatible =
        "undefined" !== typeof(compatibility[browserCategory][browser.name]) &&
        compareVersions(
          browser.version, compatibility[browserCategory][browser.name]) >= 0

      if (!isCompatible) {

        const compatibleNavigatorsList =
          Object.keys(compatibility[browserCategory]).map(
            browser => `${browser} >= ${compatibility[browserCategory][browser]}`
          )

        this.advices.push(`
            Este navegador puede tener incompatibilidades con esta página y
            podría no verse correctamente. Navegadores compatibles:
            ${compatibleNavigatorsList.join(' ')}
            `
        );
      }

    }

    calculateAdvices(){
      this.checkers.forEach((checker) => checker())
    }

    componentDidMount(){
      this.calculateAdvices()
    }

    notify() {
        setTimeout(() => {
          this.advices.forEach((adviceItem, index) => {
                toast.info(adviceItem, {
                    ...this.defaultToastConfig,
                })
            });
          }, this.notifyDelay)
    }

    render(){
      return (
        <div>
          {this.notify()}
        </div>
      );
    }
}

export default Advices;