import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import {MATERIALS} from '@/globals';

import styles from "./CertificateSummary.css";

function CertificateSummary(props) {

    const materials_summary = Object.keys(props.certificate.materials).map((material) => {
        let tons = props.certificate.materials[material].tons
        let m3 = props.certificate.materials[material].m3
        let quantity = "";

        if (tons !== 0) {
            quantity += `${tons} toneladas`
        }
        if (m3 !== 0) {
            quantity += `, ${m3} m³`
        }

        return (
            <Row key={material}>
                <Col xs={7} lg={9}>
                    <b>{MATERIALS[material]}:</b>
                </Col>
                <Col xs={5} lg={3} className={styles.materialColQuantity}>
                    {quantity}
                </Col>
            </Row>
        )
    })

    return (<Container className={`${styles.CertificateSummaryContainer}`}>
                <h4 className="grey-150">Duración de la obra:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Inicio: </b>{props.certificate.dateRange.start.format("DD/MM/YYYY")}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Fin: </b>{props.certificate.dateRange.end.format("DD/MM/YYYY")}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Datos de la obra:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>{props.certificate.workData.streetType}: </b>{props.certificate.workData.street}
                            </li>
                            <li>
                                <b>Número: </b>{props.certificate.workData.number}
                            </li>
                            <li>
                                <b>CP: </b>{props.certificate.workData.pc}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Ciudad: </b>{props.certificate.workData.city}
                            </li>
                            <li>
                                <b>Provincia: </b>{props.certificate.workData.province}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Descripción: </b>{props.certificate.workData.description}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Licence: </b>{props.certificate.workData.licenceNumber}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Datos del productor:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Nombre: </b>{props.certificate.producerData.name}
                            </li>
                            <li>
                                <b>CIF: </b>{props.certificate.producerData.cif}
                            </li>
                            <li>
                                <b>{props.certificate.producerData.streetType}: </b>{props.certificate.producerData.street}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Número: </b>{props.certificate.producerData.number}
                            </li>
                            <li>
                                <b>CP: </b>{props.certificate.producerData.pc}
                            </li>
                            <li>
                                <b>Ciudad: </b>{props.certificate.producerData.city}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Datos del constructor:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Nombre: </b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.name :
                                        props.certificate.builderData.name}
                            </li>
                            <li>
                                <b>CIF: </b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.cif :
                                        props.certificate.builderData.cif}
                            </li>
                            <li>
                                <b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.streetType :
                                        props.certificate.builderData.streetType}: </b>{
                                            props.certificate.producerBuilderEqual ?
                                                props.certificate.producerData.street :
                                                props.certificate.builderData.street}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Número: </b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.number :
                                        props.certificate.builderData.number}
                            </li>
                            <li>
                                <b>CP: </b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.pc :
                                        props.certificate.builderData.pc}
                            </li>
                            <li>
                                <b>Ciudad: </b>{
                                    props.certificate.producerBuilderEqual ?
                                        props.certificate.producerData.city :
                                        props.certificate.builderData.city}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Desglose de materiales:</h4>
                <Row>
                    <Col>
                        <ul>
                            {materials_summary}
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Comentarios:</h4>
                <Row>
                    <Col>
                        <ul>
                            {props.certificate.comments}
                        </ul>
                    </Col>
                </Row>
            </Container>)
}

export default CertificateSummary;