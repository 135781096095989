import ApiHandler from '@/api/ApiHandler';

// Enpoints alias
const ENDPOINTS = 'pickup';

class ApiPickupHandler extends ApiHandler {

    submitPickup(data, handler) {

        const args = [null, data, handler]

        this.apiHandler(this.api.put, ENDPOINTS, ...args);
    }
}

export default ApiPickupHandler;