import React, { Component, Fragment } from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';

import ApiContact from './ApiContact';

// Components
import CustomForm from '@/components/Form';
import InfoModal from '@/components/Modal/InfoModal';

// Assets
import styles from './Contact.css';

class Contact extends Component {

    constructor(props){
        super(props);

        this.state = {
            form: {
                inputName: "",
                inputEmail: "",
                inputAnswer: "",
                inputPolicy: false
            },
            modalShowed: false,
            apiError: false
        };

        this.api = new ApiContact();

        this.handleModalClose = this.handleModalClose.bind(this);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleApiResponse = this.handleApiResponse.bind(this);
    }

    // Handler Methods

    handleModalClose(){
        this.setState({
            modalShowed: false,
            apiError: false
        });
    }

    handleFormChange(event) {
        event.persist();
        let inputId = `input${event.target.name}`;

        let value = event.target.name === 'Policy' ? event.target.checked : event.target.value

        this.setState((prevState) => {
            return {
                form: {
                    ...prevState.form,
                    [inputId]: value
                }
            };
        });
    }

    handleSubmit() {
        this.sendAnswer();
        this.resetForm();
    }

    handleApiResponse(status, data) {
        const apiError = status >= 400
        this.setState({
            modalShowed: true,
            apiError: apiError
        });
    }

    // Methods

    resetForm() {
        this.setState({
            form: {
                inputName: "",
                inputEmail: "",
                inputAnswer: "",
                inputPolicy: false
            }
        })
    }

    sendAnswer() {
        let data = {
            name: this.state.form.inputName,
            email: this.state.form.inputEmail,
            message: this.state.form.inputAnswer
        }
        this.api.sendAnswer(data, this.handleApiResponse);
    }

    // Render Methods

    renderTitle() {
        return (
            <div className="section_title text-center mb50 ">
                <h3 className="title">
                    Estamos en contacto
                    <span className="heading_line"></span>
                </h3>
                <p>
                    Si tienes cualquier duda, no dudes en preguntarnos:
                </p>
            </div>
        )
    }

    renderForm() {

        function renderButton(){
            return (
                <Form.Row>
                    <Col xs={12}>
                        <Button variant="rounded" type="submit" className={`btn-primary ${styles.formButton}`}>
                            Enviar
                        </Button>
                    </Col>
                </Form.Row>
            );
        }

        return (
            <Row className={styles.contactForm}>
                <CustomForm button={renderButton()}
                    policy={this.state.form.inputPolicy}
                    submitHandler={this.handleSubmit}
                    policyHandler={this.handleFormChange}
                >
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>*Nombre</Form.Label>
                                <Form.Control type="text"
                                    name="Name"
                                    required
                                    placeholder="Nombre y Apellidos"
                                    value={this.state.form.inputName}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>*Email</Form.Label>
                                <Form.Control type="email"
                                    required
                                    name="Email"
                                    placeholder="Email"
                                    value={this.state.form.inputEmail}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>*Mensaje</Form.Label>
                                <Form.Control as="textarea"
                                    required
                                    name="Answer"
                                    rows="10"
                                    cols="40"
                                    placeholder="Tu mensaje"
                                    value={this.state.form.inputAnswer}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </CustomForm>
            </Row>
        )
    }

    renderInfoModal() {

        let sucess = `Tu pregunta ha sido enviada. En breve recibirás un email
                      informándote.`;
        let error = `Ha ocurrido un error en el servidor y no se garantiza que el mensaje haya sido enviado`;

        let message = this.state.apiError ? error : sucess;

        return (
            <p>{message}</p>
        )

    }

    render() {
        return (
            <Fragment>
                <section id="contact"  className="grey-50">
                    <Container className="pt120 pb120">
                        <Row className="justify-content-center">
                            <Col md={7}>
                                {this.renderTitle()}
                                {this.renderForm()}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <InfoModal show={this.state.modalShowed}
                    handleClose={this.handleModalClose}
                    title="Envío de mensaje"
                >
                    {this.renderInfoModal()}
                </InfoModal>
            </Fragment>
        );
    }
}

export default Contact;