import React, { Component, Fragment } from 'react';
import {Form, Row, Col, Container, Button} from 'react-bootstrap';

import ApiOrder from './ApiOrder'

// Config
import {phone} from '@/globals';

// Components

import CustomForm from '@/components/Form';

import OrderModal from '../../../components/Modal/ConfirmModal';
import InfoModal from '../../../components/Modal/InfoModal';

import styles from './OrderForm.css';

class OrderForm extends Component {

    constructor(props) {
        super(props);

        // States keys are name attribute from inputs
        this.state = {
            order: {
                name: '',
                email: '',
                phone: '',
                street: '',
                number: '',
                city: '',
                pc: '',
                province: '',
                bigBags: 0,
                smallBags: 0,
                comments: '',
                inputPolicy: false
            },
            orderInvalid: false,
            modalShowed: false,
            modalConfirmShowed: false,
            apiError: false
        };

        this.minBigBags = 20;
        this.stepBigBags = 10;

        this.minSmallBags = 1000;
        this.stepSmallBags = 1000;

        this.api = new ApiOrder();

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormChangeBigBags = this.handleFormChangeBigBags.bind(this);
        this.handleFormChangeSmallBags = this.handleFormChangeSmallBags.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlerApiResponse = this.handlerApiResponse.bind(this);

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
    }

    // Handlers

    handleFormChange(event){
        event.persist();

        let value = event.target.name === 'Policy' ? event.target.checked : event.target.value
        let name = event.target.name === 'Policy' ? 'inputPolicy' : event.target.name

        this.setState((prevState) => {
            return {
                order: {
                    ...prevState.order,
                    [name]: value
                }
            }
        });
    }

    handleFormChangeBigBags(event) {
        event.persist();
        let value =  event.target.value;
        const last_value = this.state.order.bigBags;

        value = value > last_value ? Math.max(value, this.minBigBags) :
                                     value < this.minBigBags ? 0 : value;

        this.setState((prevState) => {
            return {
                order: {
                    ...prevState.order,
                    bigBags: value
                },
                orderInvalid: false,
            }
        });
    }

    handleFormChangeSmallBags(event) {
        event.persist();
        let value =  event.target.value;
        const last_value = this.state.order.smallBags;

        value = value > last_value ? Math.max(value, this.minSmallBags) :
                                     value < this.minSmallBags ? 0 : value;

        this.setState((prevState) => {
            return {
                order: {
                    ...prevState.order,
                    smallBags: value
                },
                orderInvalid: false,
            }
        });
    }

    handleSubmit() {
        if (this.state.order.bigBags + this.state.order.smallBags > 0) {
            this.setState({orderInvalid: false})
            this.showModal()
        } else {
            this.setState({orderInvalid: true})
        }
    }

    handlerApiResponse(status, data) {
        const apiError = status >= 400

        this.setState({
            modalConfirmShowed: true,
            apiError: apiError
        });
    }

    // confirm modal handler

    showModal(){
        this.setState({modalShowed: true});
    }

    closeModal() {
        this.setState({modalShowed: false});
    }

    closeModalConfirm() {
        this.setState({
            modalConfirmShowed: false,
            apiError: false
        });
    }

    confirmOrder() {
        this.api.submitOrder(this.state.order, this.handlerApiResponse)
        this.setState({modalShowed: false});
    }

    // Renders methods

    renderContact() {
        return (
            <Fragment>
                <small className="form-text text-muted">Contacto:</small>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="name"
                                required
                                placeholder="Nombre de la empresa"
                                value={this.state.order.name}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="email"
                                required
                                name="email"
                                placeholder="Email de contacto"
                                value={this.state.order.email}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="phone"
                                required
                                placeholder="Teléfono"
                                value={this.state.order.phone}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderAddress() {
        return (
            <Fragment>
                <small className="form-text text-muted">Direccion de entrega</small>
                <Form.Row>
                    <Col xs={8} md={4}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="street"
                                required
                                placeholder="Calle"
                                value={this.state.order.street}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} md={2}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="number"
                                required
                                placeholder="Nº"
                                value={this.state.order.number}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                        <Form.Group>
                            <Form.Control type="text"
                                name="city"
                                required
                                placeholder="Poblacion"
                                value={this.state.order.city}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                        <Form.Group>
                            <Form.Control type="number"
                                name="pc"
                                required
                                placeholder="CP"
                                value={this.state.order.pc}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Control type="text"
                                name="province"
                                required
                                placeholder="Provincia"
                                value={this.state.order.province}
                                onChange={this.handleFormChange}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderOrder() {
        return (
            <Fragment>
                <Form.Row>
                    <Col>
                        <small className="form-text text-muted">Sacos de 1m<sup>3</sup> (mínimo 20 unidades)</small>
                        <Form.Group>
                            <Form.Control type="number"
                                name="bigBags"
                                placeholder="0"
                                isInvalid={this.state.orderInvalid}
                                min="0"
                                step={this.stepBigBags.toString()}
                                value={this.state.order.bigBags}
                                onChange={this.handleFormChangeBigBags}
                            />
                            <Form.Control.Feedback type="invalid">
                                No se puede realizar un pedido de 0 sacos
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <small className="form-text text-muted">Sacos rafia (mínimo 1000 unidades)</small>
                        <Form.Group>
                            <Form.Control type="number"
                                name="smallBags"
                                placeholder="0"
                                isInvalid={this.state.orderInvalid}
                                min="0"
                                step={this.stepSmallBags.toString()}
                                value={this.state.order.smallBags}
                                onChange={this.handleFormChangeSmallBags}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Fragment>
        )
    }

    renderComments() {
        return (
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Control as="textarea"
                            name="comments"
                            cols="40"
                            rows="5"
                            placeholder="Comentarios"
                            value={this.state.order.comments}
                            onChange={this.handleFormChange}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
        )
    }

    renderButton() {
        return (
            <Form.Row>
                <Col xs={12}>
                    <Button variant="rounded" type="submit" className={`btn-primary ${styles.formButton}`}>
                        Realizar pedido
                    </Button>
                </Col>
            </Form.Row>
        );
    }

    renderOrderInfo() {
        let sucess = `Hemos recibido su pedido. En breve recibirá un email con los datos del pedido`;
        let error = `Ha ocurrido un error en el servidor y no se garantiza que
                     el pedido haya sido recibido. Por favor antes de realizar otro pedido
                     llame a <a href="tel:${phone}">${phone}</a> para confirmar si ha sido recibido o no.`;

        let message = this.state.apiError ? error : sucess;

        return (
            <p dangerouslySetInnerHTML={{__html: message}}></p>
        )
    }

    render() {
        return (
            <Fragment>
                <CustomForm button={this.renderButton()}
                    policy={this.state.order.inputPolicy}
                    submitHandler={this.handleSubmit}
                    policyHandler={this.handleFormChange}
                    errorMsg={`Asegurese de haber insertado una cantidad de sacos en el pedido`}
                >
                    {this.renderContact()}
                    {this.renderAddress()}
                    {this.renderOrder()}
                    {this.renderComments()}
                </CustomForm>
                <OrderModal title="Confirmación de pedido"
                    show={this.state.modalShowed}
                    handleCancel={this.closeModal}
                    handleConfirm={this.confirmOrder}>
                    <OrderSummary order={this.state.order}/>
                </OrderModal>
                <InfoModal title="Información de pedido"
                    show={this.state.modalConfirmShowed}
                    handleClose={this.closeModalConfirm}>
                    {this.renderOrderInfo()}
                </InfoModal>
            </Fragment>
        )
    }
}

function OrderSummary(props) {

    return (<Container className={`${styles.OrderSummaryContainer}`}>
                <h4 className="grey-150">Datos de contacto:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Nombre: </b>{props.order.name}
                            </li>
                            <li>
                                <b>Email: </b>{props.order.email}
                            </li>
                            <li>
                                <b>Telefono: </b>{props.order.phone}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Calle: </b>{props.order.street}
                            </li>
                            <li>
                                <b>Número: </b>{props.order.number}
                            </li>
                            <li>
                                <b>Población: </b>{props.order.city}
                            </li>
                            <li>
                                <b>CP: </b>{props.order.pc}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Datos del pedido:</h4>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>
                                <b>Sacos grandes: </b>{props.order.bigBags}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                    <ul>
                            <li>
                                <b>Sacos pequeños: </b>{props.order.smallBags}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="grey-150">Comentarios</h4>
                <Row>
                    <p className={styles.OrderSummaryComment}>
                        {props.order.comments}
                    </p>
                </Row>
            </Container>)
}

export default OrderForm;