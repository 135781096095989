import React, { Component, Fragment } from 'react';
import CookieConsent from "react-cookie-consent";

// Components
import CookiesModal from '@/components/Modal/CookiesModal';

// Assets
import styles from './CookieAlert.css';


class CookieAlert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cookiesModalShowed: false
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    showModal() {
        this.setState({
            cookiesModalShowed: true
        });
    }

    closeModal(){
        this.setState({
            cookiesModalShowed: false
        });
    }

    render() {
        return (
            <Fragment>
                <CookieConsent
                    buttonText="Acepto"
                    buttonClasses={styles.cookieButton}
                >
                    Este sitio web utiliza cookies propias y de terceros que ayuda a
                    optimizar su visita y mejorar su experiencia de usuario. No se
                    utilizarán las cookies para recoger información de carácter
                    personal. Si continúa navegando entendemos que acepta nuestra
                    política de cookies. Puede Encontrar más información en nuestra
                    <a href="#null" data-modal="cookies" onClick={this.showModal}> Política de Cookies.</a>
                </CookieConsent>
                <CookiesModal show={this.state.cookiesModalShowed} handleClose={this.closeModal} />
            </Fragment>
        )
    }
}


export default CookieAlert;