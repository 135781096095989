// Bussiness data

export const phone = '677528478';

// Web environment data

export const STREET_TYPES = [
    "Calle",
    "Avenida",
    "Paseo",
    "Travesía",
    "Costanilla",
    "Camino",
    "Callejón",
    "Bulevar",
    "Carretera",
    "Corredera",
    "Plaza",
    "Urbanización"
];

export const CITIES = {
    Madrid: [40.417, -3.7035],
    Alcobendas: [40.5402, -3.6361],
    Alcorcón: [40.3502,-3.8274],
    'Boadilla del Monte': [40.4057, -3.8758],
    Coslada: [40.4238, -3.5553],
    Fuenlabrada: [40.2824, -3.7917],
    Getafe: [40.3078, -3.7305],
    'Las Rozas de Madrid': [40.4934, -3.8758],
    Leganés: [40.3284, -3.7649],
    Majadahonda: [40.4737, -3.8725],
    'Mejorada del Campo': [40.3973, -3.4897],
    Móstoles: [40.3236, -3.8648],
    Parla: [40.2377, -3.7717],
    'Pozuelo de Alarcón': [40.4355, -3.814],
    'Rivas - Vaciamadrid': [40.3544,-3.5304],
    'San Fernando de Henares': [40.425, -3.5349],
    'San Sebastián de los Reyes': [40.5477, -3.6265],
    'Velilla de San Antonio': [40.3677, -3.4876],
    'Villaviciosa de Odón': [40.3578, -3.9001],
};

export const MATERIALS = {
    '101': 'Hormigón',
    '102': 'Ladrillos',
    '103': 'Tejas y materiales cerámicos',
    '107': 'Mezclas de hormigón, ladrillos, tejas y materiales cerámicos',
    '201': 'Madera',
    '202': 'Vidrio',
    '203': 'Plástico',
};